import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import Image from "components/Image";
import { useScrollbarWidth } from "hooks";
import useIsInEditMode from "hooks/useIsInEditMode";
import { BREAKPOINT_NUMBER } from "style/breakpoints";
import generateSrcSet from "style/generateSrcSet";

import {
    BlockQuoteWrapper,
    Container,
    ImageWrapper,
    StyledBlockQuote,
    StyledSection,
} from "./BlockQuoteSection.styled";
import BlockQuoteSectionProps from "./BlockQuoteSectionProps";

const BlockQuoteSection = ({
    identifier,
    quoteText,
    className,
    theme = "lightgray",
    contentTheme = "black",
    image,
    author,
    anchorName,
    link,
}: BlockQuoteSectionProps): ReactElement => {
    const isInEditMode = useIsInEditMode();

    // Fix for old QuoteSectionWithImageBlock themes
    if (theme === "lightgray" && contentTheme === "white") {
        theme = "lightgray";
        contentTheme = "black";
    }

    // Override some image specific parameters
    if (image && image.url) {
        image.srcSet = generateSrcSet({
            image: image.url,
            focalPoint: image.focalPoint,
            maxWidth: image.uploadedWidth,
            maxHeight: image.uploadedHeight,
            format: "jpg",
            content: [
                {
                    width: 2560,
                    breakpoint: BREAKPOINT_NUMBER.XXXL,
                },
                {
                    width: 1920,
                    breakpoint: BREAKPOINT_NUMBER.XXL,
                },
                {
                    width: 1680,
                    breakpoint: BREAKPOINT_NUMBER.XL,
                },
                {
                    width: 1365,
                    breakpoint: BREAKPOINT_NUMBER.L,
                },
                {
                    width: 992,
                    breakpoint: BREAKPOINT_NUMBER.M,
                },
                {
                    width: 768,
                    height: 768,
                },
            ],
        });
    }

    const scrollbarWidth = useScrollbarWidth();

    return (
        <StyledSection
            colorTheme={theme}
            id={anchorName}
            $scrollbarWidth={scrollbarWidth || 0}
        >
            <ThemeProvider
                theme={{
                    theme: theme,
                    contentTheme: contentTheme,
                    hasImage: !!(image && image.url),
                    isInEditMode: isInEditMode,
                }}
            >
                <Container key={identifier || `QuoteSectionContent`}>
                    {image?.url && (
                        <ImageWrapper>
                            <Image {...image} caption="" cover={true} />
                        </ImageWrapper>
                    )}
                    <BlockQuoteWrapper>
                        <StyledBlockQuote
                            className={className}
                            quoteText={quoteText}
                            author={author}
                            theme={theme}
                            link={link}
                            size="large"
                        />
                    </BlockQuoteWrapper>
                </Container>
            </ThemeProvider>
        </StyledSection>
    );
};

export default React.memo(BlockQuoteSection);
