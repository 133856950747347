import { styled, css } from "styled-components";
import theme from "styled-theming";

import BlockQuote from "components/BlockQuote";
import Section from "components/Section";
import { Container as GridContainer } from "style/components/Page";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

const backgroundColor = theme("theme", {
    undefined: "--color-transparent",
    default: "--background-default",
    lightgray: "--background-default",
    blue: "--block-blue-default",
    cyan: "--block-cyan-default",
    coral: "--block-coral-default",
});

export const StyledSection = styled(Section).attrs<{
    $scrollbarWidth: number;
}>(() => ({}))`
    position: relative;
    align-items: center;

    ${(props) => css`
        --image-width: calc(50vw - (0.5 * var(--grid-column-gutter))) -
            (0.5 * ${props.$scrollbarWidth}px);

        ${MQ.FROM_L} {
            --image-width: calc(
                50vw - var(--grid-column-size) -
                    (1.5 * var(--grid-column-gutter)) -
                    (0.5 * ${props.$scrollbarWidth}px)
            );
        }
    `}
`;

export const Container = styled(GridContainer)`
    ${columnGap}
    align-items: center;
    padding-block: 0;

    ${theme("hasImage", {
        true: css`
            align-items: center;
            display: grid;
            grid-template-columns: repeat(4, [col-start] 1fr);
            grid-template-rows:
                calc(0.7 * var(--image-width))
                1fr;

            &::after {
                ${MQ.FROM_M} {
                    display: block;
                    grid-row: 2 / span 2;
                    z-index: 2;
                    background-color: var(${backgroundColor});
                    grid-column: col-start 6 / span 3;
                    content: "";
                    width: calc(
                        2 *
                            (
                                var(--grid-column-size) +
                                    var(--grid-column-gutter)
                            )
                    );
                    height: 100%;
                }

                ${MQ.FROM_L} {
                    grid-column: col-start 7 / span 3;
                }
            }
        `,
        false: css`
            display: flex;
            padding-block: 2.1875rem;
        `,
    })}

    ${MQ.FROM_M} {
        min-height: 80vh;

        ${theme("hasImage", {
            true: css`
                grid-template-columns: repeat(12, [col-start] 1fr);
                grid-template-rows:
                    minmax(4.375rem, auto)
                    min-content
                    minmax(4.375rem, auto);
                padding-block: 0;
            `,
            false: css`
                padding-block: 3.5rem;
            `,
        })}
    }

    ${MQ.FROM_L} {
        min-height: 100vh;
        ${theme("hasImage", {
            true: css`
                grid-template-rows:
                    minmax(8.75rem, auto)
                    min-content
                    minmax(8.75rem, auto);
            `,
        })}
    }

    ${MQ.FROM_XL} {
        ${theme("hasImage", {
            true: css`
                grid-template-rows:
                    minmax(6.5625rem, auto)
                    min-content
                    minmax(6.5625rem, auto);
            `,
            false: css`
                padding-bottom: 4.375rem;
                padding-top: 4.375rem;
            `,
        })}
    }

    ${theme("isInEditMode", {
        true: css`
            min-height: auto;

            ${MQ.FROM_L} {
                min-height: auto;
            }
        `,
    })}

    figure {
        z-index: 1;
    }
`;

export const BlockQuoteWrapper = styled.div`
    background-color: var(${backgroundColor});
    z-index: 3;

    ${theme("hasImage", {
        true: css`
            grid-column: col-start 1 / span 4;
            grid-row: 2;
            padding-bottom: 3.5rem;
            padding-right: 0.875rem;
            padding-top: 0.875rem;

            ${MQ.FROM_M} {
                grid-column: col-start 1 / span 7;
                padding-block: 2.1875rem;
                padding-right: 0;
            }

            ${MQ.FROM_L} {
                grid-column: col-start 1 / span 8;
            }
            ${MQ.FROM_XL} {
                padding-block: 3.5rem;
            }
        `,
    })}
`;

export const StyledBlockQuote = styled(BlockQuote)`
    z-index: 2;
`;

export const ImageWrapper = styled.div`
    aspect-ratio: 1;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: var(--image-width);
    z-index: 2;

    ${MQ.FROM_M} {
        aspect-ratio: unset;
        bottom: 0;
        grid-row: 1 / span 3;
    }
`;
